import {
   Box,
   Button,
   ClickAwayListener,
   Grow,
   MenuItem,
   MenuList,
   Paper,
   Popper,
   Tab,
   Tabs,
   Typography,
} from "@mui/material";
import { useContext, useRef, useState } from "react";
import { GroupCompaniesTable } from "../GroupCompaniesTables";
import { ServiceInfoProvider } from "../../../context/serviceInfoContext";
import { ClientTypeModal } from "../../Clients/ClientTypeModal";
import { CompaniesSelectorComponet } from "../../Clients/SubComponents/CompaniesSelectorComponent";
import { ClientConfigContext } from "../../../context/clientConfigContext";
import { AddCircle } from "@mui/icons-material";
import ClientCreationTabsContainer from "../../Clients/SubComponents/ClientCreationTabsContainer";
import { TabPanel } from "../../TabPanel";

const GroupCompanies = () => {
   const [value, setValue] = useState(0);
   const { setClientInfo } = useContext(ClientConfigContext);
   const anchorRef = useRef<HTMLButtonElement>(null);
   const [open, setOpen] = useState(false);
   const [openModal, setOpenModal] = useState<boolean>(false);
   const [activeStep2, setActiveStep2] = useState(0);
   const [completedCompany2, setCompletedCompany2] = useState(false);
   const [createCompanyInGroup, setCreateCompanyInGroup] = useState(false);
   const [clientSelected2, setClientSelected2] = useState<number>(0);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   const handleCancel = () => {
      setClientSelected2(0);
      setActiveStep2(0);
      setCompletedCompany2(false);
      setOpenModal(false);
   };
   const handleCancel2 = () => {
      setClientSelected2(0);
      setActiveStep2(0);
      setCompletedCompany2(false);
      setCreateCompanyInGroup(!createCompanyInGroup);
   };

   const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
   };

   const handleClose = (event: Event | React.SyntheticEvent) => {
      if (anchorRef.current?.contains(event.target as HTMLElement)) {
         return;
      }
      setOpen(false);
   };

   function handleListKeyDown(event: React.KeyboardEvent) {
      if (event.key === "Tab") {
         event.preventDefault();
         setOpen(false);
      } else if (event.key === "Escape") {
         setOpen(false);
      }
   }

   return (
      <Box>
         <Box
            display="flex"
            sx={{
               borderBottom: 1,
               borderColor: "divider",
               borderTopRightRadius: 5,
               borderTopLeftRadius: 5,
               display: "flex",
               justifyContent: "space-between",
            }}
            bgcolor="white"
            boxShadow={2}
         >
            <Tabs value={value} onChange={handleChange}>
               <Tab sx={{ fontSize: 14, fontWeight: 600 }} label="Empresas" />
            </Tabs>
            <Button
               variant="contained"
               sx={{ minHeight: "100%", margin: 1, bgcolor: "#162C44", width: "180px" }}
               size="small"
               ref={anchorRef}
               onClick={() => {
                  setOpenModal(!openModal);
               }}
            >
               <AddCircle sx={{ marginRight: 1 }} />
               <Typography ref={anchorRef} variant="button" sx={{ mr: 1 }}>
                  Agregar Empresa
               </Typography>
            </Button>
            <Popper
               open={open}
               anchorEl={anchorRef.current}
               role={undefined}
               placement="bottom-start"
               transition
               disablePortal
               style={{ zIndex: 20, width: "180px" }}
            >
               {({ TransitionProps, placement }) => (
                  <Grow
                     {...TransitionProps}
                     style={{
                        transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
                     }}
                  >
                     <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                           <MenuList
                              autoFocusItem={open}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDown}
                           >
                              <MenuItem
                                 onClick={(event) => {
                                    setClientInfo(undefined);
                                    setActiveStep2(0);
                                    setOpenModal(!openModal);
                                    setClientSelected2(1);
                                    handleToggle();
                                    handleClose(event);
                                 }}
                              >
                                 Persona física
                              </MenuItem>
                              <MenuItem
                                 onClick={(event) => {
                                    setClientInfo(undefined);
                                    setActiveStep2(0);
                                    setOpenModal(!openModal);
                                    setClientSelected2(2);
                                    handleToggle();
                                    handleClose(event);
                                 }}
                              >
                                 Persona moral
                              </MenuItem>
                           </MenuList>
                        </ClickAwayListener>
                     </Paper>
                  </Grow>
               )}
            </Popper>
         </Box>

         <TabPanel value={value} index={0}>
            <GroupCompaniesTable />
         </TabPanel>
         <ServiceInfoProvider>
            <ClientTypeModal
               open={openModal}
               setOpen={handleCancel}
               title="Agregar empresa al grupo empresarial"
               subtitle={`${new Date()}`}
               body={
                  <CompaniesSelectorComponet
                     setCompletedCompany={() => {}}
                     handleCancel={handleClose}
                     selectedClient={clientSelected2}
                     handleCreate={() => setCreateCompanyInGroup(true)}
                     setValue={setActiveStep2}
                     setClientSelected={setClientSelected2}
                     setTypeClient={setClientSelected2}
                     itsOnClientconfiguration={true}
                  />
               }
            />
            <ClientTypeModal
               open={createCompanyInGroup}
               setOpen={handleCancel2}
               title="Agregar empresa al grupo empresarial"
               subtitle={`${new Date()}`}
               isOnGroup={true}
               body={
                  <ClientCreationTabsContainer
                     clientSelected={clientSelected2}
                     activeStep={activeStep2}
                     setActiveStep={setActiveStep2}
                     setCompletedCompany={setCompletedCompany2}
                     handleCancel={handleCancel2}
                     completedCompany={completedCompany2}
                     setClientSelected={() => {}}
                  />
               }
            />
         </ServiceInfoProvider>
      </Box>
   );
};

export default GroupCompanies;
