import { Box, Stack, Switch, Typography } from "@mui/material";
import { ConfirmationModal } from "../ConfirmationModal";
import { useContext, useState } from "react";
import { SnackBarContext } from "../../context/snackBarContext";
import { createBeneficiaryApplicationConfig, useUpdateServiceDisable } from "../../lib/usersBEClient";
import { ClientConfigContext } from "../../context/clientConfigContext";
import { IServiceDetails } from "../../types/BaseTypes";
import { BeneficiaryRequiredDocs } from "../../const/globalConst";
import LoginModal from "../ClientInformation/Modal/loginModal";

interface ModuleCheckProps {
   service: { _id: string; name: string };
}

function getService(services: IServiceDetails[], serviceId: string) {
   for (const service of services) {
      if (service.serviceId._id.toString() === serviceId) {
         return service;
      }
   }
}

const ModuleCheck = (props: ModuleCheckProps) => {
   const { service } = props;
   const { showSnackBar } = useContext(SnackBarContext);
   const { clientInfo, refreshClientInfo } = useContext(ClientConfigContext);

   const { mutate: serviceStatus } = useUpdateServiceDisable();

   const serviceDetails = getService(clientInfo.company_details?.servicesDetails, service._id);
   const [checked, setChecked] = useState<boolean>(serviceDetails !== undefined && !serviceDetails?.disable);
   const [openDisableServiceModal, setOpenDisableServiceModal] = useState(false);
   const [openLoginModal, setOpenLoginModal] = useState(false);

   const serviceUpdate = async () => {
      serviceStatus(
         {
            service_id: props.service._id,
            companyDetails_id: clientInfo.company_details._id,
            disable: checked,
         },
         {
            onSuccess: async () => {
               showSnackBar(`Servicio ${!checked ? "Habilitado" : "Inhabilitado"} correctamente`, false);
               if (!checked && service.name === "Beneficiario controlador") {
                  const data = {
                     company: clientInfo._id,
                     files: BeneficiaryRequiredDocs.map((file) => {
                        return {
                           fileName: file,
                           fileType: "PDF",
                           maxFileSize: 10 * (1024 * 1024),
                           periodType: "monthly",
                           periodFrequency: 3,
                        };
                     }),
                  };
                  await createBeneficiaryApplicationConfig(data);
               }
               refreshClientInfo();
            },
            onError: () => {
               showSnackBar(`Error al ${!checked ? "Habilitar" : "Inhabilitar"}`, true);
               refreshClientInfo();
            },
         }
      );
   };

   return (
      <>
         <Box
            sx={{
               alignItems: "center",
               display: "flex",
               justifyContent: "space-between",
            }}
         >
            <Typography fontWeight={600} variant="subtitle1">
               {service.name}
            </Typography>
            <Stack direction={"row"} columnGap={2}>
               <Switch
                  checked={checked}
                  onChange={() => {
                     setOpenDisableServiceModal(true);
                  }}
                  sx={{ display: "flex" }}
               />
            </Stack>
         </Box>
         <ConfirmationModal
            open={openDisableServiceModal}
            setOpen={setOpenDisableServiceModal}
            title={!checked ? `Habilitar servicio` : `Inhabilitar servicio`}
            body={`¿Está seguro de ${!checked ? "habilitar" : "inhabilitar"} este servicio?`}
            onConfirm={() => {}}
            setOpenLogin={setOpenLoginModal}
         />
         <LoginModal
            open={openLoginModal}
            setOpen={setOpenLoginModal}
            onConfirm={() => {
               serviceUpdate();
               setChecked(!checked);
            }}
         />
      </>
   );
};

export default ModuleCheck;
