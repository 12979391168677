import { Box, Tab, Tabs } from "@mui/material";
import React, { useContext, useState } from "react";
import { GroupInformationComponent } from "../GroupInformationComponent";
import { InvoiceGroupInfoComponent } from "../InvoiceGroupInfoComponent";
import ClientStatusAccount from "../Company/Subcomponents/ClientStatusAccount";
import { ClientConfigContext } from "../../../context/clientConfigContext";
import { TabPanel } from "../../TabPanel";

const GroupInformation = () => {
   const [value, setValue] = useState(0);
   const { group } = useContext(ClientConfigContext);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue);

   return (
      <Box>
         <Box
            display="flex"
            sx={{ borderBottom: 1, borderColor: "divider", borderTopRightRadius: 5, borderTopLeftRadius: 5 }}
            bgcolor="white"
            boxShadow={2}
         >
            <Tabs value={value} onChange={handleChange}>
               <Tab sx={{ fontSize: 14, fontWeight: 600 }} label="Información general" />
               <Tab sx={{ fontSize: 14, fontWeight: 600 }} label="Facturas" />
            </Tabs>
         </Box>
         <TabPanel value={value} index={0}>
            <GroupInformationComponent />
         </TabPanel>
         <TabPanel value={value} index={1}>
            <InvoiceGroupInfoComponent />
            {group.invoiceCompany ? <ClientStatusAccount /> : null}
         </TabPanel>
      </Box>
   );
};

export default GroupInformation;
