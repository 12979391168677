import { Box, Tab, Tabs } from "@mui/material";
import { ClientInformationHome } from "../ClientInformationHome";
import { BranchesTableClientInfo } from "./Subcomponents/BranchesTable";
import { AdditionalContacts } from "../AdditionalContacts";
import { InvoiceClientInfoComponent } from "./Subcomponents/InvoiceClientInfoComponent";
import ClientStatusAccount from "./Subcomponents/ClientStatusAccount";
import { useCallback, useContext, useEffect, useState } from "react";
import { UseBranchesByCompanyId } from "../../../lib/usersBEClient";
import { useParams } from "react-router-dom";
import { TabPanel } from "../../TabPanel";
import { ClientConfigContext } from "../../../context/clientConfigContext";

const CompanyInfoTab = () => {
   const [value, setValue] = useState(0);
   const [branches, setBranches] = useState<any[]>();
   const { clientInfo, group } = useContext(ClientConfigContext);
   const { clientId } = useParams();

   const clientInfoEffect = useCallback(async () => {
      if (group?.invoiceCompany) {
         const responseBranches = await UseBranchesByCompanyId(group.invoiceCompany._id);
         setBranches(responseBranches);
      } else {
         const responseBranches = await UseBranchesByCompanyId(clientId);
         setBranches(responseBranches);
      }
   }, [clientInfo, clientId, group]);

   useEffect(() => {
      clientInfoEffect();
   }, [clientInfoEffect]);

   const listBranches = !branches
      ? []
      : branches.map((e) => ({
           _id: e._id,
           name: e.name,
           state: e.state,
           city: e.city,
           phoneNumber: e.phoneNumber,
           address: e.address,
           zipCode: e.zipCode,
           disabled: e.disabled,
           createdAt: e.createdAt,
        }));

   const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue);

   return (
      <>
         <Box
            display="flex"
            sx={{ borderBottom: 1, borderTopRightRadius: 5, borderTopLeftRadius: 5, borderColor: "#E0E0E0" }}
            bgcolor="white"
            boxShadow={2}
         >
            <Tabs value={value} onChange={handleChange}>
               <Tab sx={{ fontSize: 14, fontWeight: 600 }} label="Información general" />
               <Tab sx={{ fontSize: 14, fontWeight: 600 }} label="Facturas" />
            </Tabs>
         </Box>
         <TabPanel value={value} index={0}>
            <ClientInformationHome />
            <Box my={2}>
               <BranchesTableClientInfo branches={listBranches} />
            </Box>
            <AdditionalContacts />
         </TabPanel>
         <TabPanel value={value} index={1}>
            <InvoiceClientInfoComponent />
            <ClientStatusAccount />
         </TabPanel>
      </>
   );
};

export default CompanyInfoTab;
