import { Box, Tab, Tabs } from "@mui/material";
import { ClientCompanyAdminInformationComponent } from "../ClientCompanyAdminInformationComponent";
import { useState } from "react";
import { CompanieUsersTable } from "../CompanieUsersTable";
import { TabPanel } from "../../TabPanel";

const CompanyUsersTab = () => {
   const [value, setValue] = useState(0);
   const handleChange = (_event: React.SyntheticEvent, newValue: number) => setValue(newValue);

   return (
      <>
         <Box
            display="flex"
            sx={{ borderBottom: 1, borderTopRightRadius: 5, borderTopLeftRadius: 5, borderColor: "#E0E0E0" }}
            bgcolor="white"
            boxShadow={2}
            width={"100%"}
         >
            <Tabs value={value} onChange={handleChange}>
               <Tab sx={{ fontSize: 14, fontWeight: 600 }} label="Información de administrador de sistema" />
            </Tabs>
         </Box>
         <TabPanel value={value} index={0}>
            <ClientCompanyAdminInformationComponent group={false} />
         </TabPanel>

         <Box
            display="flex"
            sx={{ borderBottom: 1, borderTopRightRadius: 5, borderTopLeftRadius: 5, borderColor: "#E0E0E0" }}
            bgcolor="white"
            boxShadow={2}
            mt={2}
         >
            <Tabs value={value} onChange={handleChange}>
               <Tab sx={{ fontSize: 14, fontWeight: 600, cursor: "default" }} label="Información de usuarios" />
            </Tabs>
         </Box>
         <TabPanel index={0} value={value}>
            <CompanieUsersTable group={false} />
         </TabPanel>
      </>
   );
};

export default CompanyUsersTab;
