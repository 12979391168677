import {
   Box,
   Divider,
   Typography,
   Avatar,
   List,
   ListItem,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   Container,
   CircularProgress,
   Modal,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { TitleAppBar } from "../../components/TitleAppBar";
import HomeIcon from "@mui/icons-material/Home";
import { ClientConfigContext } from "../../context/clientConfigContext";
import { useParams } from "react-router-dom";
import { getUrlS3 } from "../../lib/usersBEClient";
import ScrollToTop from "../../const/scrollToTop";
import CompanyInfoTab from "../../components/ClientInformation/Company/CompanyInfoTab";
import CompanyUsersTab from "../../components/ClientInformation/Company/CompanyUsersTab";
import CompanyServiceTab from "../../components/ClientInformation/Company/CompanyServiceTab";

export const ClientInformationScreen = () => {
   const { clientInfo, getClientInfo } = useContext(ClientConfigContext);
   const { clientId } = useParams();
   const [menuState, setMenuState] = useState(0);
   const [logoUrl, setLogoUrl] = useState("");
   const [openLogo, setOpenLogo] = useState(false);

   useEffect(() => {
      const fetchData = async () => {
         await getClientInfo(clientId);
      };
      fetchData();
      if (clientId) getLogo(clientId);
      // eslint-disable-next-line
   }, [clientId]);

   const menu = [
      {
         name: "Inicio",
         icon: <HomeIcon sx={{ color: "#2d4357" }} />,
         value: 0,
      },
      {
         name: "Cliente",
         icon: <PermContactCalendarIcon sx={{ color: "#2d4357" }} />,
         value: 1,
      },
      {
         name: "Servicios",
         icon: <AdminPanelSettingsIcon sx={{ color: "#2d4357" }} />,
         value: 2,
      },
   ];

   const getLogo = async (clientId: string) => {
      const url = await getUrlS3("images-lecosy", { folder: clientId }, "logo.png");
      setLogoUrl(url);
   };

   const TabContent = () => {
      switch (menuState) {
         case 0:
            return <CompanyInfoTab />;
         case 1:
            return <CompanyUsersTab />;
         case 2:
            return <CompanyServiceTab />;
         default:
            break;
      }
   };

   return (
      <ScrollToTop>
         <Box display="flex" flexDirection="column" p={2}>
            <TitleAppBar head="Configuración del cliente" isScheduleAppointment={false} />
            <Container maxWidth="xl">
               {clientInfo && logoUrl ? (
                  <Box display="flex" sx={{ py: 1 }} columnGap={3}>
                     <Box sx={{ flex: 1 }}>
                        <Box bgcolor="white" borderRadius={1} boxShadow={2}>
                           <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={3}>
                              <Avatar
                                 onClick={async () => setOpenLogo(true)}
                                 alt={`${clientInfo?.person_details?.comercialName?.charAt(0)}`}
                                 src={logoUrl}
                                 sx={{
                                    width: 100,
                                    height: 100,
                                    fontSize: 30,
                                    border: 1,
                                    borderColor: "#E3E2E2",
                                    ":hover": { cursor: "pointer" },
                                 }}
                                 imgProps={{ sx: { objectFit: "contain" } }}
                              />
                              <Modal
                                 open={openLogo}
                                 onClose={() => {
                                    setOpenLogo(false);
                                 }}
                                 sx={{ display: "flex" }}
                              >
                                 <Box sx={{ m: "auto", maxWidth: "60%" }}>
                                    <Box component={"img"} width={"100%"} maxHeight={600} src={logoUrl} />
                                 </Box>
                              </Modal>
                              <Typography fontWeight={400} mt={0.5}>
                                 {clientInfo?.person_details?.comercialName}
                              </Typography>
                           </Box>

                           <Divider flexItem sx={{ my: 1 }} />
                           <Box>
                              <Typography mx={2} alignSelf="flex-start" fontWeight={500} variant="h6">
                                 Menú
                              </Typography>
                              <List>
                                 {menu.map((item, i) => (
                                    <ListItem key={i} disablePadding sx={{ py: 0.5, px: 1 }}>
                                       <ListItemButton
                                          selected={item.value === menuState}
                                          onClick={() => setMenuState(item.value)}
                                          sx={{
                                             borderRadius: 3,
                                          }}
                                       >
                                          <ListItemIcon>{item.icon}</ListItemIcon>
                                          <ListItemText primary={item.name} />
                                       </ListItemButton>
                                    </ListItem>
                                 ))}
                              </List>
                           </Box>
                        </Box>
                     </Box>
                     <Box sx={{ flex: 4 }}>
                        <TabContent />
                     </Box>
                  </Box>
               ) : (
                  <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                     <CircularProgress />
                  </Box>
               )}
            </Container>
         </Box>
      </ScrollToTop>
   );
};
