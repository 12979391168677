import { useEffect, useContext, useState } from "react";
import {
   Box,
   Button,
   Typography,
   FormControl,
   InputLabel,
   Select,
   MenuItem,
   CircularProgress,
   Tabs,
   Tab,
} from "@mui/material";
import Container from "@mui/material/Container/Container";
import { TopHeader } from "../components/TopHeader";
import { ClientTypeModal } from "../components/Clients/ClientTypeModal";
import { UserDetailsComponent } from "../components/Clients/SubComponents/UserDetailsComponent";
import {
   GetAllGroups,
   getAllCompanies,
   useGetInCompletedCompanies,
   useGetInCompletedGroups,
} from "../lib/usersBEClient";
import { ClientConfigContext } from "../context/clientConfigContext";
import { HeadCell, TableComponent } from "../components/TableComponent";
import { GroupClientCreationTabs } from "../components/Clients/SubComponents/GroupClientCreationTabs";
import { GroupDetailsComponent } from "../components/Clients/SubComponents/GroupDetailsComponent";
import { CompaniesSelectorComponet } from "../components/Clients/SubComponents/CompaniesSelectorComponent";
import { UserAdminGroupComponent } from "../components/Clients/SubComponents/UserAdminGroupComponent";
import { InvoicesDetailsGroupComponent } from "../components/Clients/SubComponents/InvoicesDetailsGroupComponent";
import { ServiceInfoProvider } from "../context/serviceInfoContext";
import { getDateAndTimeFromISODate } from "../const/globalConst";
import { DualTableComponent } from "../components/DualTableComponent";
import { Companies, Group } from "../types/BaseTypes";
import { useNavigate } from "react-router-dom";
import ClientCreationTabsContainer from "../components/Clients/SubComponents/ClientCreationTabsContainer";
import { UserContext } from "../context/UserContext";
import { CustomModalComponent } from "../components/CustomModalComponent";

const companyColums: HeadCell[] = [
   { field: "comercialName", headerName: "Nombre comercial", type: "string", width: 50, align: "left" },
   { field: "businessName", headerName: "Razón social", type: "string", width: 50 },
   { field: "createdAt", headerName: "Fecha de creación", type: "string", width: 50, align: "center" },
];

const incompletedGroupsColums: HeadCell[] = [
   { field: "name", headerName: "Nombre comercial", type: "string", width: 50, align: "left" },
   { field: "createdAt", headerName: "Fecha de creación", type: "string", width: 50, align: "right" },
];

const incompletedCompanyColums: HeadCell[] = [
   { field: "comercialName", headerName: "Nombre comercial", type: "string", width: 50, align: "left" },
   { field: "businessName", headerName: "Razón social", type: "string", width: 50 },
   { field: "createdAt", headerName: "Fecha de creación", type: "string", width: 50, align: "right" },
];

export function ClientsManagementScreen() {
   const navigate = useNavigate();
   const { data, isLoading: isLoadingCompanies, refetch: refetchIC } = useGetInCompletedCompanies();
   const { data: dataIGroups, isLoading: isLoadingIncompletedGroups, refetch: refetchIG } = useGetInCompletedGroups();
   const { getClientInfo, setClientInfo, setGroup, getGroupInfo, group, clientInfo } = useContext(ClientConfigContext);
   const { GetPermission } = useContext(UserContext);
   const { data: groups, isLoading: isLoadingGroups, refetch: refetchG } = GetAllGroups();
   const [openModal, setOpenModal] = useState<boolean>(false);
   const [clientSelected, setClientSelected] = useState<number>(0);
   const [clientSelected2, setClientSelected2] = useState<number>(0);
   const [activeStep, setActiveStep] = useState(0);
   const [activeStep2, setActiveStep2] = useState(0);
   const [clientSelection, setClientSelection] = useState(false);
   const [completedCompany, setCompletedCompany] = useState(false);
   const [completedCompany2, setCompletedCompany2] = useState(false);
   const [company, setCompany] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [createCompanyInGroup, setCreateCompanyInGroup] = useState(false);
   const [showTable, setShowTable] = useState(0);
   const [selectedTab, setSelectedTab] = useState(0);
   const [reload, setReload] = useState(false);
   const [key, setKey] = useState(0);
   const [click, setClick] = useState(false);

   const openCreationModal = (event) => {
      setClientSelected(event.target.value);
   };
   const access = GetPermission("Clientes");

   interface TabPanelProps {
      children?: React.ReactNode;
      index: number;
      value: number;
   }

   function TabPanel(props: Readonly<TabPanelProps>) {
      const { children, value, index, ...other } = props;

      return (
         <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{ flex: 1 }}
         >
            {value === index && <Box sx={{ display: "flex" }}>{children}</Box>}
         </div>
      );
   }

   function a11yProps(index: number) {
      return {
         id: `full-width-tab-${index}`,
         "aria-controls": `full-width-tabpanel-${index}`,
      };
   }
   const handleCancel = async () => {
      setClientSelected(0);
      setActiveStep(0);
      setCompletedCompany(false);
      setClientSelection(false);
      setOpenModal(false);
      const isCompanyCompleted =
         clientInfo?.company_details?.admin &&
         clientInfo.company_details?.users.length > 1 &&
         clientInfo.company_details?.servicesDetails.length > 0 &&
         clientInfo.invoice_details;
      const isGroupCompleted = group?.admin && group?.users.length > 0 && group?.companies.length > 0;
      if (isCompanyCompleted || isGroupCompleted) {
         setReload(true);
         await refetchIC();
         await refetchIG();
         await refetchG();
         setKey((prevKey) => prevKey + 1);
      }
   };
   const handleCancel2 = () => {
      setClientSelected2(0);
      setActiveStep2(0);
      setCompletedCompany2(false);
      setCreateCompanyInGroup(false);
   };
   useEffect(() => {
      if (clientSelected === 0) {
         setCompletedCompany(false);
         setActiveStep(0);
         setClientInfo(null);
      }
      // eslint-disable-next-line
   }, [clientSelected]);

   useEffect(() => {
      if (!createCompanyInGroup) {
         setClientInfo(null);
      }
      // eslint-disable-next-line
   }, [createCompanyInGroup]);

   const typeOptions = ["Seleccionar", "Persona Física", "Persona Moral", "Grupo empresarial", "Despacho"];

   const handleTableRowClick = async (_e, row) => {
      setClientSelection(false);
      if (row.type) {
         setClientSelected(row.type === "natural" ? 1 : 2);
         await getClientInfo(row._id);
      } else {
         setClientSelected(3);
         await getGroupInfo(row._id);
      }
      setCompletedCompany(true);
   };

   const handleTableRowClickNew = async (_e, row) => {
      setGroup(undefined);
      setClick(true);
      if (!click) {
         if (row.name) {
            const screen = "grupo/" + row.id;
            await getGroupInfo(row.id);
            return navigate(screen);
         } else {
            const screen = row.id;
            await getClientInfo(row.id);
            return navigate(screen);
         }
      }
   };

   const handleTableTabChange = (event: React.SyntheticEvent, newValue: number) => {
      setShowTable(newValue);
      setSelectedTab(newValue);
   };
   const groupColums: HeadCell[] = [
      {
         field: "name",
         headerName: "Nombre del grupo empresarial",
         type: "string",
         align: "left",
      },
      {
         field: "abrir",
         headerName: "",
         type: "collapsible",
         align: "right",
      },
   ];

   useEffect(() => {
      const fetchCompanies = async () => {
         const companyResponseObtained = await getAllCompanies();
         const companyResponse: any[] = [];
         for (const companyResponseFilter of companyResponseObtained) {
            if (!companyResponseFilter.group) {
               companyResponse.push(companyResponseFilter);
            }
         }
         const companyInfo = companyResponse.map((user) => ({
            id: user._id,
            comercialName: user.person_details.comercialName,
            businessName: user.person_details.businessName ? user.person_details.businessName : "",
            createdAt: getDateAndTimeFromISODate(user.createdAt),
            unformatedDate: new Date(user.createdAt.split("T")[0]).getTime(),
         }));
         const filteredCompanies = companyInfo.filter((company) => {
            const incompleteNames = data.map((ic) => ic._id);
            return !incompleteNames.includes(company.id);
         });
         setCompany(filteredCompanies);
         setIsLoading(false);
      };
      if (!isLoadingCompanies) fetchCompanies();
      // eslint-disable-next-line
   }, [isLoadingCompanies]);

   useEffect(() => {
      const fetchCompanies = async () => {
         const companyResponseObtained: any = await getAllCompanies();
         const companyResponse: any[] = [];
         for (const companyResponseFilter of companyResponseObtained) {
            if (!companyResponseFilter.group) {
               companyResponse.push(companyResponseFilter);
            }
         }
         const companyInfo = companyResponse.map((user) => ({
            id: user._id,
            comercialName: user.person_details.comercialName,
            businessName: user.person_details.businessName ? user.person_details.businessName : "",
            createdAt: getDateAndTimeFromISODate(user.createdAt),
            unformatedDate: new Date(user.createdAt.split("T")[0]).getTime(),
         }));

         const filteredCompanies = companyInfo.filter((company) => {
            const incompleteNames = data.map((ic) => ic._id);
            return !incompleteNames.includes(company.id);
         });
         setCompany(filteredCompanies);
         setIsLoading(false);
      };
      if (reload) {
         setReload(false);
         fetchCompanies();
      }
      // eslint-disable-next-line
   }, [reload]);

   const groupMap = isLoadingGroups
      ? []
      : groups.map((group: Group) => ({
           id: group._id,
           name: group.name,
           users: group.users.length,
           companies: group.companies.map((companies: Companies) => ({
              id: companies._id,
              comercialName: companies.person_details.comercialName,
              createdAt: getDateAndTimeFromISODate(companies.createdAt),
              unformatedDate: new Date(companies.createdAt.split("T")[0]).getTime(),
              services:
                 companies?.company_details?.servicesDetails
                    ?.filter((service) => !service.disable)
                    .map((service) => service.serviceId.service) || [],
              users: companies.company_details ? companies.company_details.users.length : 0,
           })),
        }));

   return (
      <Box sx={{ padding: 2, display: "flex", flexDirection: "column", backgroundColor: "#F5F5F5" }}>
         <TopHeader head="Gestión de clientes" content={<></>} />
         <Container maxWidth="xl" key={key}>
            {!isLoading && !isLoadingGroups ? (
               <DualTableComponent
                  rowsOne={company}
                  rowsTwo={groupMap}
                  tableOneProps={{
                     defaultColumnToOrder: "name",
                     defaultOrder: "asc",
                     defaultRowsPerPage: 5,
                     emptyDataText: "No se encontraron clientes registrados",
                     rowsPerPageOptions: [5, 10, 15, 20],
                     loader: isLoading,
                     headers: companyColums,
                     onClick: handleTableRowClickNew,
                     disableBorders: true,
                  }}
                  tableTwoProps={{
                     defaultColumnToOrder: "name",
                     defaultOrder: "asc",
                     defaultRowsPerPage: 5,
                     emptyDataText: "No se encontraron grupos registrados",
                     rowsPerPageOptions: [5, 10, 15, 20],
                     loader: isLoadingGroups,
                     headers: groupColums,
                     onClick: handleTableRowClickNew,
                     onClickCollapsible: handleTableRowClickNew,
                     collapsible: true,
                     disableBorders: true,
                  }}
                  labelOne="Empresas"
                  labelTwo="Grupos empresariales"
                  onClick={() => {
                     setClientInfo(undefined);
                     setGroup(undefined);
                     setOpenModal(!openModal);
                  }}
                  disabled={access}
               />
            ) : (
               <Box
                  sx={{
                     height: "70vh",
                     width: "90vw",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                  }}
               >
                  <CircularProgress />
               </Box>
            )}
         </Container>
         <ServiceInfoProvider>
            <CustomModalComponent
               open={openModal}
               setOpen={handleCancel}
               title="Agregar nuevo cliente"
               timeStamp
               maxWidth="1000px"
            >
               <>
                  {clientSelected === 1 || clientSelected === 2 || clientSelected === 4 ? (
                     <ClientCreationTabsContainer
                        clientSelected={clientSelected}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        setCompletedCompany={setCompletedCompany}
                        handleCancel={handleCancel}
                        completedCompany={completedCompany}
                        setClientSelected={setClientSelected}
                     />
                  ) : clientSelected === 3 ? (
                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: "column",
                           flex: 1,
                           p: 2,
                           minWidth: 1000,
                        }}
                     >
                        <Box sx={{ display: "flex" }}>
                           <Box sx={{ flex: 1, px: 2 }}>
                              <GroupClientCreationTabs
                                 value={activeStep}
                                 setValue={setActiveStep}
                                 completed={completedCompany}
                              />
                           </Box>
                           <Box
                              sx={{
                                 flex: 3,
                                 display: "flex",
                                 px: 2,
                                 flexDirection: "column",
                                 maxHeight: 570,
                                 overflowY: "auto",
                              }}
                           >
                              <TabPanel value={activeStep} index={0}>
                                 <GroupDetailsComponent
                                    setCompletedCompany={setCompletedCompany}
                                    setValue={setActiveStep}
                                    setClientSelected={setClientSelected}
                                 />
                              </TabPanel>
                              <TabPanel value={activeStep} index={1}>
                                 <CompaniesSelectorComponet
                                    setCompletedCompany={setCompletedCompany}
                                    handleCancel={handleCancel}
                                    selectedClient={clientSelected}
                                    handleCreate={() => setCreateCompanyInGroup(true)}
                                    setValue={setActiveStep}
                                    setClientSelected={setClientSelected}
                                    setTypeClient={setClientSelected2}
                                    itsOnClientconfiguration={false}
                                 />
                              </TabPanel>
                              <TabPanel value={activeStep} index={2}>
                                 <UserAdminGroupComponent
                                    handleCancel={setClientSelected}
                                    setValue={setActiveStep}
                                    value={activeStep}
                                 />
                              </TabPanel>
                              <TabPanel value={activeStep} index={3}>
                                 <UserDetailsComponent
                                    setSelectedClient={setClientSelected}
                                    handleCancel={handleCancel}
                                    setValue={setActiveStep}
                                    value={activeStep}
                                 />
                              </TabPanel>
                              <TabPanel value={activeStep} index={4}>
                                 <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                    <InvoicesDetailsGroupComponent
                                       handleCancel={() => setClientSelected(0)}
                                       setClientSelected={setClientSelected}
                                    />
                                 </Box>
                              </TabPanel>
                           </Box>
                        </Box>
                     </Box>
                  ) : data && !isLoadingCompanies && clientSelected === 0 && !clientSelection ? (
                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: "column",
                           flex: 1,
                           minWidth: 800,
                           p: 4,
                        }}
                     >
                        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                           <Typography sx={{ textAlign: "center" }}>INFORMACIÓN PENDIENTE</Typography>
                           <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                              <Tabs
                                 value={selectedTab}
                                 onChange={handleTableTabChange}
                                 variant="fullWidth"
                                 sx={{
                                    ".MuiTabs-indicator": {
                                       marginLeft: `calc((33% - 100px) / 2)`,
                                       width: "100px !important",
                                       backgroundColor: "#162c44",
                                       height: "2px",
                                    },
                                 }}
                              >
                                 {["Empresas", "Grupos empresariales", "Despachos"].map((label, index) => (
                                    <Tab
                                       key={label}
                                       {...a11yProps(index)}
                                       label={label}
                                       sx={{
                                          width: index === 0 ? "10px" : "auto",
                                          color: selectedTab === index ? "#162c44" : "inherit",
                                          fontWeight: selectedTab === index ? "bold" : "normal",
                                       }}
                                    />
                                 ))}
                              </Tabs>
                           </Box>
                        </Box>
                        <Box hidden={showTable !== 0}>
                           <TableComponent
                              defaultColumnToOrder=""
                              defaultOrder="asc"
                              defaultRowsPerPage={5}
                              emptyDataText="No hay empresas pendientes por el momento"
                              rowsPerPageOptions={[3, 5]}
                              loader={isLoadingCompanies}
                              headers={incompletedCompanyColums}
                              rows={data}
                              onClick={handleTableRowClick}
                              disableBorders
                           />
                        </Box>

                        <Box hidden={showTable !== 1}>
                           <TableComponent
                              defaultColumnToOrder=""
                              defaultOrder="asc"
                              defaultRowsPerPage={5}
                              emptyDataText="No hay grupos empresariales pendientes por el momento"
                              rowsPerPageOptions={[3, 5]}
                              loader={isLoadingIncompletedGroups}
                              headers={incompletedGroupsColums}
                              rows={dataIGroups}
                              onClick={handleTableRowClick}
                              disableBorders
                           />
                        </Box>

                        <Box hidden={showTable !== 2}>
                           <TableComponent
                              defaultColumnToOrder=""
                              defaultOrder="asc"
                              defaultRowsPerPage={5}
                              emptyDataText="No hay despachos pendientes por el momento"
                              rowsPerPageOptions={[3, 5]}
                              loader={false}
                              headers={incompletedCompanyColums}
                              rows={[]}
                              onClick={handleTableRowClick}
                              disableBorders
                           />
                        </Box>

                        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
                           <Button sx={{ height: "100%" }} variant="outlined" onClick={handleCancel}>
                              Cancelar
                           </Button>
                           <Button
                              sx={{ height: "100%" }}
                              variant="contained"
                              color="primary"
                              onClick={() => setClientSelection(true)}
                           >
                              Crear nuevo
                           </Button>
                        </Box>
                     </Box>
                  ) : (
                     <Box sx={{ flex: 1 }}>
                        <Box
                           sx={{
                              border: 1,
                              borderColor: "#E0E0E0",
                              borderBottomRightRadius: 2,
                              borderBottomLeftRadius: 2,
                           }}
                        >
                           <Box
                              sx={{
                                 p: 4,
                                 borderTop: 1,
                                 borderColor: "#E0E0E0",
                                 display: "flex",
                                 columnGap: 2,
                                 alignItems: "center",
                              }}
                           >
                              <Typography>Tipo de cliente:</Typography>
                              <FormControl sx={{ minWidth: 300 }}>
                                 <InputLabel id="type">Seleccionar</InputLabel>
                                 <Select
                                    labelId="type"
                                    id="type"
                                    value={clientSelected}
                                    label="Seleccionar"
                                    onChange={openCreationModal}
                                 >
                                    {typeOptions.map((option, index) => (
                                       <MenuItem
                                          key={index}
                                          value={index}
                                          sx={{ display: option === "Seleccionar" ? "none" : "block" }}
                                       >
                                          {option}
                                       </MenuItem>
                                    ))}
                                 </Select>
                              </FormControl>
                           </Box>
                        </Box>
                     </Box>
                  )}
               </>
            </CustomModalComponent>
            <ClientTypeModal
               open={createCompanyInGroup}
               setOpen={handleCancel2}
               title="Agregar empresa al grupo empresarial"
               subtitle={`${new Date()}`}
               isOnGroup={true}
               body={
                  <ClientCreationTabsContainer
                     clientSelected={clientSelected2}
                     activeStep={activeStep2}
                     setActiveStep={setActiveStep2}
                     setCompletedCompany={setCompletedCompany2}
                     handleCancel={handleCancel2}
                     completedCompany={completedCompany2}
                     setClientSelected={() => {}}
                  />
               }
            />
         </ServiceInfoProvider>
      </Box>
   );
}
