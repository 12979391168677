import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { useContext, useState, useEffect, useMemo } from "react";
import {
   Box,
   Button,
   Stack,
   Typography,
   Accordion,
   AccordionSummary,
   AccordionDetails,
   IconButton,
   CircularProgress,
   Checkbox,
   Modal,
   List,
   ListItem,
   ListItemText,
   Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ServiceInfoContext } from "../../../../context/serviceInfoContext";
import { platformInfoSchema } from "../../../../lib/Validations/inputSchemas";
import { InputTextField } from "../../../Inputs/InputTextField";
import { ClientConfigContext } from "../../../../context/clientConfigContext";
import { SnackBarContext } from "../../../../context/snackBarContext";
import {
   CreatePlatformInfo,
   UpdatePlatformInfo,
   getManyCompaniesById,
   getS3LastModifiedDate,
   getUrlS3,
} from "../../../../lib/usersBEClient";
import { AddCircleRounded, RemoveCircleRounded } from "@mui/icons-material";
import { LoadingButton } from "../../../LoadingButton";
import { InputImage } from "../../../Inputs/InputImage";
import { uploadFileToS3, UseGetPresignedURLUpload } from "../../../../lib/s3Client";
import { InputFile } from "../../../Inputs/InputFile";
import { getDateFromISODate } from "../../../../const/globalConst";
import { CustomModalComponent } from "../../../CustomModalComponent";
import { SuggestionsModal } from "./SuggestionsModal";

interface FileWithDateInterface {
   file: any;
   lastDateModified: Date;
}

interface ImageFlyerInterface {
   file: any;
   name: string;
   fileType: string;
}
export const ComplaintsFormComponent = (props: { modify?: boolean; checked: boolean; service: any }) => {
   const { showSnackBar } = useContext(SnackBarContext);
   const { getService } = useContext(ServiceInfoContext);
   const { clientInfo, refreshClientInfo } = useContext(ClientConfigContext);
   const serviceDetails = getService(clientInfo?.company_details?.servicesDetails, props.service._id);
   const [uploadedPoliciesBlob, setUploadedPoliciesBlob] = useState<FileWithDateInterface>(null);
   const [flyerPoliciesImageBlob, setFlyerPoliciesImageBlob] = useState<FileWithDateInterface>(null);
   const [flyerMediaImageBlob, setFlyerMediaImageBlob] = useState<FileWithDateInterface>(null);
   const [flyerProcessImageBlob, setFlyerProcessImageBlob] = useState<FileWithDateInterface>(null);
   const [editingFile, setEditingFile] = useState<ImageFlyerInterface>(null);
   const [checked, setChecked] = useState<boolean>(serviceDetails?.details?.platformId?.intern ?? false);
   const [totalLicenses, setTotalLicenses] = useState<number>(serviceDetails ? serviceDetails.userLimit : 3);
   const [licensesUsed, setLicensesUsed] = useState<number>(0);
   const [isLoadingLicenses, setIsLoadingLicenses] = useState(true);
   const { mutate: CreatePlatform, isLoading: isLoadingCreate } = CreatePlatformInfo();
   const { mutate: UpdatePlatform, isLoading: isLoadingUpdate } = UpdatePlatformInfo(clientInfo.company_details._id);
   const [isLoadingFiles, setIsLoadingFiles] = useState(true);
   const [openMedia, setOpenMedia] = useState(false);
   const [openPolicies, setOpenPolicies] = useState(false);
   const [openProcess, setOpenProcess] = useState(false);
   const [seed, setSeed] = useState(1);
   const [companiesWithNames, setCompaniesWithNames] = useState([]);

   const getPolicies = async (clientId: string) => {
      const logoDate: Date = await getS3LastModifiedDate("images-lecosy", { folder: clientId }, "policies.pdf");
      const logoUrl: string = await getUrlS3("images-lecosy", { folder: clientId }, "policies.pdf");
      await fetch(logoUrl).then(async (data) => {
         const newBlob = await data.blob();
         const file = new File([newBlob], "politicas.pdf", { type: "application/pdf" });
         setUploadedPoliciesBlob({ file: file, lastDateModified: logoDate });
      });
   };

   const getPoliciesFlyer = async (clientId: string) => {
      const logoDate: Date = await getS3LastModifiedDate(
         "files-lecosy",
         { folder: `diffusionCamps/${clientId}` },
         "policiesFlyer.png"
      );
      const logoUrl: string = await getUrlS3(
         "files-lecosy",
         { folder: `diffusionCamps/${clientId}` },
         "policiesFlyer.png"
      );
      await fetch(logoUrl).then(async (data) => {
         const newBlob = await data.blob();
         const file = new File([newBlob], "policiesFlyer.png", { type: "image/png" });
         setFlyerPoliciesImageBlob({ file: file, lastDateModified: logoDate });
      });
   };
   const getMediaFlyer = async (clientId: string) => {
      const logoDate: Date = await getS3LastModifiedDate(
         "files-lecosy",
         { folder: `diffusionCamps/${clientId}` },
         "mediaFlyer.png"
      );
      const logoUrl: string = await getUrlS3(
         "files-lecosy",
         { folder: `diffusionCamps/${clientId}` },
         "mediaFlyer.png"
      );
      await fetch(logoUrl).then(async (data) => {
         const newBlob = await data.blob();
         const file = new File([newBlob], "mediaFlyer.png", { type: "image/png" });
         setFlyerMediaImageBlob({ file: file, lastDateModified: logoDate });
      });
   };
   const getProcessFlyer = async (clientId: string) => {
      const logoDate: Date = await getS3LastModifiedDate(
         "files-lecosy",
         { folder: `diffusionCamps/${clientId}` },
         "processFlyer.png"
      );
      const logoUrl: string = await getUrlS3(
         "files-lecosy",
         { folder: `diffusionCamps/${clientId}` },
         "processFlyer.png"
      );
      await fetch(logoUrl).then(async (data) => {
         const newBlob = await data.blob();
         const file = new File([newBlob], "processFlyer.png", { type: "image/png" });
         setFlyerProcessImageBlob({ file: file, lastDateModified: logoDate });
      });
   };

   const getLicenses = (company) => {
      const roles: any[] = [];
      const usersIds: string[] = [];
      const usersArray = company.company_details.users;
      for (const users of usersArray) {
         for (const access of users.role) {
            if (usersIds.includes(users._id)) break;
            for (const rol of access.roles) {
               roles.push(rol);
               usersIds.push(users._id);
            }
         }
      }
      const rolesByCD = roles.filter(
         (rol) => rol.name === "Auditor" || rol.name === "Director" || rol.name === "Oficial de cumplimiento"
      );
      setLicensesUsed(rolesByCD.length);
   };

   useEffect(() => {
      if (serviceDetails?.details?.platformId && !uploadedPoliciesBlob) getPolicies(clientInfo._id);
      if (serviceDetails?.details?.platformId && !flyerPoliciesImageBlob) getPoliciesFlyer(clientInfo._id);
      if (serviceDetails?.details?.platformId && !flyerMediaImageBlob) getMediaFlyer(clientInfo._id);
      if (serviceDetails?.details?.platformId && !flyerProcessImageBlob) getProcessFlyer(clientInfo._id);
      setChecked(serviceDetails?.details?.platformId ? serviceDetails.details.platformId.intern : false);
      setTotalLicenses(serviceDetails?.details?.platformId ? serviceDetails.userLimit : 3);
      setIsLoadingFiles(false);
      // eslint-disable-next-line
   }, [seed]);

   useEffect(() => {
      if (clientInfo && clientInfo.company_details.servicesDetails.length !== 0) {
         getLicenses(clientInfo);
      }
      setIsLoadingLicenses(false);
      // eslint-disable-next-line
   }, []);

   const handleGetCompaniesNames = async (companies: any[]) => {
      const data = await getManyCompaniesById(companies);
      const companiesWithCD = data.filter((c) => c.company_details.servicesDetails.some((s) => s.details));
      const companiesWithNames = companiesWithCD
         .filter((c) => c.person_details.comercialName !== clientInfo.person_details.comercialName)
         .map((company) => ({
            _id: company._id,
            name: company.person_details.comercialName,
         }));
      setCompaniesWithNames(companiesWithNames);
   };

   useEffect(() => {
      if (clientInfo.group) handleGetCompaniesNames(clientInfo.group.companies);
   }, [clientInfo]);

   useEffect(() => {
      const handleSubmitFiles = async () => {
         const fileUrl = await UseGetPresignedURLUpload({
            bucket: editingFile.fileType === "pdf" ? "images-lecosy" : "files-lecosy",
            folder: editingFile.fileType === "pdf" ? `${clientInfo._id}` : `diffusionCamps/${clientInfo._id}`,
            name: `${editingFile.name}.${editingFile.fileType}`,
         });
         await uploadFileToS3(fileUrl.data, editingFile.file);
         setEditingFile(null);
      };
      if (editingFile) handleSubmitFiles();
   }, [editingFile]);

   const handleSubmit = (values) => {
      if (!uploadedPoliciesBlob || !flyerMediaImageBlob || !flyerPoliciesImageBlob || !flyerProcessImageBlob) {
         return showSnackBar("Favor de adjuntar archivos correspondientes", true);
      }
      try {
         if (serviceDetails?.details?.platformId) {
            UpdatePlatform(
               {
                  platform_info: {
                     intern: checked,
                     manager: values.manager,
                     subName: values.subName,
                     message: values.message,
                     privacy_policies: uploadedPoliciesBlob.file.name,
                     flyers: [
                        flyerPoliciesImageBlob.file.name,
                        flyerMediaImageBlob.file.name,
                        flyerProcessImageBlob.file.name,
                     ],
                  },
                  totalLicenses: totalLicenses,
               },
               {
                  onError: (error: any) => {
                     showSnackBar("Error al actualizar detalles de módulo", true);
                  },
                  onSuccess: async () => {
                     showSnackBar("Los detalles del módulo fueron actualizados correctamente.", false);
                     return await refreshClientInfo();
                  },
               }
            );
         } else {
            CreatePlatform(
               {
                  platform_info: {
                     intern: checked,
                     manager: values.manager,
                     subName: values.subName,
                     message: values.message,
                     privacy_policies: uploadedPoliciesBlob.file.name,
                     flyers: [
                        flyerPoliciesImageBlob.file.name,
                        flyerMediaImageBlob.file.name,
                        flyerProcessImageBlob.file.name,
                     ],
                  },
                  totalLicenses: totalLicenses,
                  company: clientInfo._id,
                  service: props.service,
               },
               {
                  onError: (error: any) => {
                     showSnackBar("Error al agregar detalles de módulo", true);
                  },
                  onSuccess: async () => {
                     showSnackBar("Los detalles del módulo fueron agregados correctamente.", false);
                     await refreshClientInfo();
                  },
               }
            );
         }
      } catch (error) {
         console.log({ error });
         return;
      }
   };

   const [suggestionSeed, setSuggestionSeed] = useState(0);
   const suggestionsArray = useMemo(() => {
      return (
         clientInfo?.company_details?.servicesDetails?.find((service) => service?.details?.platformId)?.details
            ?.platformId?.suggestions || []
      );
   }, [clientInfo, suggestionSeed]);

   const [openModal, setOpenModal] = useState(false);
   const [suggestions, setSuggestions] = useState<string[]>(suggestionsArray);

   return (
      <Box
         key={seed}
         sx={{
            opacity: props.checked ? 1 : 0.5,
            pointerEvents: props.checked ? "auto" : "none",
            display: props.modify || props.checked ? "auto" : "none",
         }}
      >
         <>
            {!isLoadingLicenses ? (
               <Formik
                  initialValues={{
                     subName: serviceDetails?.details?.platformId?.subName ?? "",
                     manager: serviceDetails?.details?.platformId?.manager ?? undefined,
                     message:
                        serviceDetails?.details?.platformId?.message ??
                        `En nombre de ${clientInfo.person_details.comercialName} agradecemos tu interés en nuestra Plataforma de Canal de Denuncias. Para nosotros es muy importante contar con medios que nos permitan detectar conductas que vayan en contra de nuestro Código de Ética, fortaleciendo nuestros principios y valores. Para garantizar la confidencialidad y anonimato de tus denuncias, hemos contratado a Lecosy, una empresa especializada en cumplimiento legal y gestión de riesgos.`,
                  }}
                  validationSchema={platformInfoSchema}
                  onSubmit={handleSubmit}
               >
                  {(formProps: FormikProps<any>) => (
                     <Form>
                        <Box
                           display={"flex"}
                           alignItems={"center"}
                           mb={1}
                           sx={{
                              opacity: !formProps.values.manager ? 1 : 0.5,
                              pointerEvents: !formProps.values.manager ? "auto" : "none",
                           }}
                        >
                           <Checkbox
                              checked={checked}
                              onChange={() => {
                                 setChecked(!checked);
                              }}
                              color="primary"
                           />
                           <Typography fontSize={14}>La gestión de denuncias se realiza de manera interna.</Typography>
                        </Box>
                        <Accordion
                           disableGutters
                           elevation={0}
                           sx={{
                              bgcolor: "#EFEFEF",
                              borderRadius: 2,
                              boxShadow: 0,
                              "&:before": { backgroundColor: "transparent !important" },
                           }}
                        >
                           <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography fontSize={14} fontWeight={600}>
                                 Información del módulo
                              </Typography>
                           </AccordionSummary>
                           <AccordionDetails>
                              <Box sx={{ px: 4 }}>
                                 <Grid container gap={2} justifyContent={"center"}>
                                    <Grid item lg={props.modify ? 3 : 12}>
                                       <Typography fontSize={14} fontWeight={600}>
                                          Acceso a plataforma:
                                       </Typography>
                                       <InputTextField
                                          sx={{ mb: 0.5, flex: 3 }}
                                          variant="outlined"
                                          size="small"
                                          name="subName"
                                          id="subName"
                                          type="text"
                                          fullWidth
                                       />

                                       <Box columnGap={2} display={"flex"} flexDirection={"column"}>
                                          <Typography fontSize={14} alignSelf={"flex-start"} mt={1} fontWeight={600}>
                                             Políticas de privacidad:
                                          </Typography>
                                          <Stack direction={"column"} spacing={0.5}>
                                             <InputFile
                                                file={uploadedPoliciesBlob?.file}
                                                setFile={setUploadedPoliciesBlob}
                                                onChange={setEditingFile}
                                                keyName="policies"
                                             />
                                             <Typography
                                                fontSize={"12px"}
                                                textAlign={props.modify ? "center" : "left"}
                                             >{`Últ. modificación: ${
                                                uploadedPoliciesBlob?.lastDateModified
                                                   ? getDateFromISODate(uploadedPoliciesBlob?.lastDateModified)
                                                   : "N/A"
                                             }`}</Typography>
                                          </Stack>
                                       </Box>
                                    </Grid>
                                    {props.modify && (
                                       <Grid display={"flex"} item xs={3} justifyContent={"center"}>
                                          <Box sx={{ display: "flex", flexDirection: "column" }}>
                                             <Typography fontSize={14} fontWeight={600}>
                                                Listado de sugerencias:
                                             </Typography>
                                             <List
                                                component="nav"
                                                onClick={() => {
                                                   setOpenModal(true);
                                                }}
                                                sx={{
                                                   backgroundColor: "#EFEFEF",
                                                   width: 200,
                                                   height: 130,
                                                   border: 1,
                                                   borderColor: "#B8B8B8",
                                                   borderRadius: 1,
                                                   ":hover": {
                                                      cursor: "pointer",
                                                   },
                                                }}
                                             >
                                                {suggestions.length === 0 ? (
                                                   <ListItem>
                                                      <ListItemText
                                                         sx={{
                                                            textAlign: "center",
                                                         }}
                                                         primary="No hay sugerencias"
                                                         primaryTypographyProps={{ fontSize: "15px" }}
                                                      />
                                                   </ListItem>
                                                ) : (
                                                   suggestions.slice(0, 4).map((suggestion, index) => (
                                                      <ListItem
                                                         sx={{ paddingTop: 0, paddingBottom: 0 }}
                                                         key={suggestion}
                                                      >
                                                         <ListItemText
                                                            sx={{ textAlign: "center" }}
                                                            primary={`${index + 1}. ${
                                                               suggestion.length > 17
                                                                  ? suggestion.slice(0, 16) + "..."
                                                                  : suggestion
                                                            }`}
                                                            primaryTypographyProps={{
                                                               fontSize: "14px",
                                                               lineHeight: 1,
                                                            }}
                                                         />
                                                      </ListItem>
                                                   ))
                                                )}
                                                {suggestions.length > 4 && (
                                                   <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                                      <ListItemText
                                                         sx={{ textAlign: "center" }}
                                                         primary="Mostrar todo"
                                                         primaryTypographyProps={{ fontSize: "12px", lineHeight: 1 }}
                                                      />
                                                   </ListItem>
                                                )}
                                             </List>
                                          </Box>
                                       </Grid>
                                    )}

                                    <Grid item lg={props.modify ? 5 : 12}>
                                       <Typography fontSize={14} fontWeight={600}>
                                          Mensaje de Bienvenida:
                                       </Typography>
                                       <InputTextField
                                          variant="outlined"
                                          size="small"
                                          name="message"
                                          id="message"
                                          type="text"
                                          rows={5}
                                          multiline
                                          fullWidth={true}
                                       />
                                    </Grid>
                                 </Grid>
                                 <Typography fontSize={14} fontWeight={600} sx={{ mt: 3 }}>
                                    Flyers de campañas de difusión
                                 </Typography>
                                 <Stack
                                    direction={"row"}
                                    justifyContent="space-between"
                                    display={"flex"}
                                    sx={{ mt: 2, mb: 3 }}
                                 >
                                    <Stack display={"flex"} sx={{ alignItems: "center" }}>
                                       <Typography fontSize={13} textAlign={"center"} marginBottom={"10px"}>
                                          Políticas del canal de denuncias
                                       </Typography>
                                       {isLoadingFiles ? (
                                          <Box sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                                             <CircularProgress size={24} />
                                          </Box>
                                       ) : (
                                          <>
                                             <InputImage
                                                image={flyerPoliciesImageBlob?.file}
                                                setImage={setFlyerPoliciesImageBlob}
                                                onClick={() => setOpenPolicies(true)}
                                                onChange={setEditingFile}
                                                keyName="policiesFlyer"
                                             />
                                             <Typography fontSize={"12px"} mt={1}>{`Últ. modificación: ${
                                                flyerPoliciesImageBlob?.lastDateModified
                                                   ? getDateFromISODate(flyerPoliciesImageBlob?.lastDateModified)
                                                   : "N/A"
                                             }`}</Typography>
                                             <Modal
                                                autoFocus={false}
                                                open={openPolicies}
                                                onClose={() => {
                                                   setOpenPolicies(false);
                                                }}
                                                sx={{ display: "flex" }}
                                             >
                                                <Box sx={{ m: "auto", maxWidth: "60%" }}>
                                                   <Box
                                                      component={"img"}
                                                      width={"100%"}
                                                      maxHeight={600}
                                                      src={
                                                         flyerPoliciesImageBlob?.file &&
                                                         (typeof flyerPoliciesImageBlob?.file === "string"
                                                            ? flyerPoliciesImageBlob?.file
                                                            : URL.createObjectURL(flyerPoliciesImageBlob?.file))
                                                      }
                                                   />
                                                </Box>
                                             </Modal>
                                          </>
                                       )}
                                    </Stack>
                                    <Stack display={"flex"} sx={{ alignItems: "center", mx: 2 }}>
                                       <Typography fontSize={13} textAlign={"center"} marginBottom={"10px"}>
                                          Medios para interponer una denuncia
                                       </Typography>
                                       {isLoadingFiles ? (
                                          <Box sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                                             <CircularProgress size={24} />
                                          </Box>
                                       ) : (
                                          <>
                                             <InputImage
                                                image={flyerMediaImageBlob?.file}
                                                setImage={setFlyerMediaImageBlob}
                                                onClick={() => setOpenMedia(true)}
                                                onChange={setEditingFile}
                                                keyName="mediaFlyer"
                                             />
                                             <Typography fontSize={"12px"} mt={1}>{`Últ. modificación: ${
                                                flyerMediaImageBlob?.lastDateModified
                                                   ? getDateFromISODate(flyerMediaImageBlob?.lastDateModified)
                                                   : "N/A"
                                             }`}</Typography>
                                             <Modal
                                                autoFocus={false}
                                                open={openMedia}
                                                onClose={() => {
                                                   setOpenMedia(false);
                                                }}
                                                sx={{ display: "flex" }}
                                             >
                                                <Box sx={{ m: "auto", maxWidth: "60%" }}>
                                                   <Box
                                                      component={"img"}
                                                      width={"100%"}
                                                      maxHeight={600}
                                                      src={
                                                         flyerMediaImageBlob?.file &&
                                                         (typeof flyerMediaImageBlob?.file === "string"
                                                            ? flyerMediaImageBlob?.file
                                                            : URL.createObjectURL(flyerMediaImageBlob?.file))
                                                      }
                                                   />
                                                </Box>
                                             </Modal>
                                          </>
                                       )}
                                    </Stack>
                                    <Stack display={"flex"} sx={{ alignItems: "center" }}>
                                       <Typography fontSize={13} textAlign={"center"} marginBottom={"10px"}>
                                          Proceso de gestión de denuncia
                                       </Typography>
                                       {isLoadingFiles ? (
                                          <Box sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                                             <CircularProgress size={24} />
                                          </Box>
                                       ) : (
                                          <>
                                             <InputImage
                                                image={flyerProcessImageBlob?.file}
                                                setImage={setFlyerProcessImageBlob}
                                                onClick={() => setOpenProcess(true)}
                                                onChange={setEditingFile}
                                                keyName="processFlyer"
                                             />
                                             <Typography fontSize={"12px"} mt={1}>{`Últ. modificación: ${
                                                flyerProcessImageBlob?.lastDateModified
                                                   ? getDateFromISODate(flyerProcessImageBlob?.lastDateModified)
                                                   : "N/A"
                                             }`}</Typography>
                                             <Modal
                                                autoFocus={false}
                                                open={openProcess}
                                                onClose={() => {
                                                   setOpenProcess(false);
                                                }}
                                                sx={{ display: "flex" }}
                                             >
                                                <Box sx={{ m: "auto", maxWidth: "60%" }}>
                                                   <Box
                                                      component={"img"}
                                                      width={"100%"}
                                                      maxHeight={600}
                                                      src={
                                                         flyerProcessImageBlob?.file &&
                                                         (typeof flyerProcessImageBlob?.file === "string"
                                                            ? flyerProcessImageBlob?.file
                                                            : URL.createObjectURL(flyerProcessImageBlob?.file))
                                                      }
                                                   />
                                                </Box>
                                             </Modal>
                                          </>
                                       )}
                                    </Stack>
                                 </Stack>
                              </Box>
                           </AccordionDetails>
                        </Accordion>
                        <Stack
                           direction={"row"}
                           sx={{
                              bgcolor: "#EFEFEF",
                              borderRadius: 2,
                              px: 2,
                              py: 1,
                              my: 1,
                              justifyContent: "space-between",
                           }}
                        >
                           <Stack>
                              <Typography fontSize={12} fontWeight={600} variant="subtitle1">
                                 Licencias en uso
                              </Typography>
                              <Typography fontSize={14} textAlign={"center"} variant="subtitle1">
                                 {licensesUsed}
                              </Typography>
                           </Stack>
                           <Stack>
                              <Typography fontSize={12} fontWeight={600} variant="subtitle1">
                                 Licencias totales
                              </Typography>
                              <Typography fontSize={14} textAlign={"center"} variant="subtitle1">
                                 {totalLicenses}
                              </Typography>
                           </Stack>
                           <Stack>
                              <Typography fontSize={12} fontWeight={600} variant="subtitle1">
                                 Licencias Extra
                              </Typography>
                              <Typography fontSize={12} textAlign={"center"} variant="subtitle1">
                                 <IconButton
                                    size="small"
                                    onClick={() => {
                                       if (totalLicenses > 3) setTotalLicenses(totalLicenses - 1);
                                    }}
                                 >
                                    <RemoveCircleRounded sx={{ color: "#162c44" }} />
                                 </IconButton>
                                 <IconButton
                                    size="small"
                                    onClick={() => {
                                       setTotalLicenses(totalLicenses + 1);
                                    }}
                                 >
                                    <AddCircleRounded sx={{ color: "#162c44" }} />
                                 </IconButton>
                              </Typography>
                           </Stack>
                        </Stack>
                        <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
                           <Button
                              sx={{ height: "100%" }}
                              variant="outlined"
                              onClick={() => {
                                 setSeed(Math.random());
                              }}
                           >
                              Cancelar
                           </Button>
                           <LoadingButton
                              label={serviceDetails ? "Guardar cambios" : "Guardar"}
                              isLoading={isLoadingCreate || isLoadingUpdate}
                           />
                        </Box>
                     </Form>
                  )}
               </Formik>
            ) : (
               <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress sx={{ width: 24 }} />
               </Box>
            )}
         </>
         <CustomModalComponent
            open={openModal}
            setOpen={setOpenModal}
            title={`Lista de sugerencias`}
            timeStamp={true}
            maxWidth="600px"
         >
            <Box
               sx={{
                  minWidth: "30%",
                  height: "500px",
                  overflowY: "auto",
                  maxWidth: 600,
               }}
            >
               <SuggestionsModal
                  suggestions={suggestions}
                  setSuggestions={setSuggestions}
                  setSuggestionSeed={setSuggestionSeed}
                  onClose={() => {
                     setOpenModal(false);
                  }}
                  isOpen={openModal}
               />
            </Box>
         </CustomModalComponent>
      </Box>
   );
};
