import { useContext, useEffect, useState } from "react";
import { Box, ToggleButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import { UploadInvoiceAccordion } from "../../../Clients/UploadInvoiceAccordion";
import { InvoiceTableComponent } from "../../InvoiceTableComponent";
import UploadReceiptAccordion from "../../../Clients/UploadReceiptAccordion";
import { ReceiptTableComponent } from "../../ReceiptTableComponent";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import { ClientConfigContext } from "../../../../context/clientConfigContext";
import { UseInvoicesByCompanyId } from "../../../../lib/usersBEClient";
import CircularProgress from "@mui/material/CircularProgress";

export default function ClientStatusAccount() {
   const { clientId } = useParams();
   const { group, clientInfo } = useContext(ClientConfigContext);
   const [alignment, setAlignment] = useState("Facturas");
   const [refetchInvoice, setRefetchInvoice] = useState<boolean>(false);
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission(["Clientes", "Facturas"]);
   const [invoices, setInvoices] = useState<any[]>();

   const getInvoices = (invoices: any[]) => {
      const listInvoices = !invoices
         ? []
         : invoices.map((e) => ({
              _id: e._id,
              name: e.name,
              startPeriod: e.startPeriod,
              unformatedDate: new Date(e.startPeriod.split("T")[0]).getTime(),
              endPeriod: e.endPeriod,
              unformatedDate2: new Date(e.endPeriod.split("T")[0]).getTime(),
              file1: e.file1,
              file2: e.file2,
           }));
      setInvoices(listInvoices);
   };

   useEffect(() => {
      const clientInfoEffect = async () => {
         if (!group) {
            const responseInvoice = await UseInvoicesByCompanyId(clientId);
            getInvoices(responseInvoice);
         } else if (group.invoiceCompany) {
            const responseInvoice = await UseInvoicesByCompanyId(group.invoiceCompany._id);
            getInvoices(responseInvoice);
         }
      };
      clientInfoEffect();
      // eslint-disable-next-line
   }, [clientInfo, group, refetchInvoice]);

   const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
      if (newAlignment !== null) {
         setAlignment(newAlignment);
      }
   };

   return (
      <>
         <Stack direction="row" spacing={4} sx={{ mt: 2, display: "flex", flex: 1 }}>
            <ToggleButtonGroup
               value={alignment}
               exclusive
               onChange={handleAlignment}
               sx={{ display: "flex", justifyContent: "space-between", flex: 1, bgcolor: "white" }}
            >
               <ToggleButton value="Facturas" sx={{ flex: 1 }}>
                  <Typography>Facturas</Typography>
               </ToggleButton>
               <ToggleButton value="Comprobante" sx={{ flex: 1 }}>
                  <Typography>Complemento de pago</Typography>
               </ToggleButton>
            </ToggleButtonGroup>
         </Stack>

         {alignment === "Facturas" ? (
            <>
               {access ? (
                  <Box>
                     <UploadInvoiceAccordion setRefetch={setRefetchInvoice} />
                  </Box>
               ) : null}
               <Box mt={2}>
                  {invoices ? (
                     <InvoiceTableComponent
                        invoices={invoices}
                        setRefetch={setRefetchInvoice}
                        refetch={refetchInvoice}
                     />
                  ) : (
                     <Box width={"100%"} display="flex" justifyContent={"center"} p={1}>
                        <CircularProgress sx={{ alignSelf: "center" }} />
                     </Box>
                  )}
               </Box>
            </>
         ) : (
            <>
               {access ? (
                  <Box>
                     <UploadReceiptAccordion
                        companyId={clientId || group.invoiceCompany._id}
                        setRefetch={setRefetchInvoice}
                     />
                  </Box>
               ) : null}
               <Box mt={2}>
                  <ReceiptTableComponent />
               </Box>
            </>
         )}
      </>
   );
}
